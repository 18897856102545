import {
  Typography,
  boldBodySmallTypography,
  pxToRem,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import NoResults from '../../components/NoResults';
import PageLoader from '../../../shared/components/PageLoader';
import PageWrapper from '../../../shared/components/PageWrapper';
import ProductCard from '../../components/ProductCard';
import ProductCardBatchQueues from '../../components/ProductCardBatchQueues';
import SellerHeader from '../../components/SellerHeader';
import ConfirmAgeModal from '../../components/modals/ConfirmAgeModal';
import { useStorefrontState } from '../../../hooks/useReduxState';
import {
  getPublicStorefrontData,
  toggleConfirmAgeModal,
} from '../../../store/actions/storefrontActions';
import {
  ListViewHeader,
  LoadMoreWrapper,
  ProductsWrapper,
} from '../Seller/Seller.styles';

const LimitedAccess = ({ isPublicRoute }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { ref, inView } = useInView();
  const { publicMenu, vendor } = useStorefrontState();
  const {
    confirmAgeModal,
    hasAdditionalProduct,
    isLoadingPublicStorefrontData,
    products,
  } = publicMenu;
  const { settings, slug } = vendor;
  const { batchQueuesEnabled, isGridView } = settings;

  const [pageNumber, setPageNumber] = useState(0);

  const queryParamsParsed = queryString.parse(location.search);

  const handleClearQueryParams = () => {
    navigate(location.pathname, { replace: true });
    dispatch(
      getPublicStorefrontData({
        slug,
      })
    );
  };

  useEffect(() => {
    if (slug) {
      dispatch(
        getPublicStorefrontData({
          slug,
          queryString: queryString.stringify(queryParamsParsed),
        })
      );
    }
  }, [slug]);

  useEffect(() => {
    if (inView && hasAdditionalProduct) {
      queryParamsParsed.page_num = pageNumber + 1;

      dispatch(
        getPublicStorefrontData({
          isLoadingMore: true,
          slug,
          queryString: queryString.stringify(queryParamsParsed),
        })
      );

      setPageNumber(pageNumber + 1);
    }
  }, [inView]);

  useEffect(() => {
    if (isPublicRoute && !sessionStorage.getItem('isOfAge')) {
      dispatch(toggleConfirmAgeModal({ isOpen: true }));
    }
  }, []);

  return (
    <>
      {isLoadingPublicStorefrontData ? (
        <PageLoader />
      ) : (
        <>
          <SellerHeader />
          <PageWrapper sidePadding='small'>
            {!isGridView && (
              <ListViewHeader>
                <Typography
                  bodyStyle={boldBodySmallTypography}
                  sx={{
                    marginRight: 'auto',
                    marginLeft: pxToRem(135),
                  }}
                >
                  Product
                </Typography>
                <Typography
                  bodyStyle={boldBodySmallTypography}
                  sx={{
                    marginRight: spacing6,
                    width: pxToRem(125),
                  }}
                >
                  Cannabinoids
                </Typography>
                <Typography
                  bodyStyle={boldBodySmallTypography}
                  sx={{
                    width: pxToRem(95),
                  }}
                >
                  Size
                </Typography>
                <Typography
                  align='right'
                  bodyStyle={boldBodySmallTypography}
                  sx={{
                    marginRight: spacing6,
                    width: pxToRem(95),
                  }}
                >
                  Price
                </Typography>
              </ListViewHeader>
            )}
            <ProductsWrapper>
              {products?.length ? (
                products.map((product) =>
                  batchQueuesEnabled ? (
                    <ProductCardBatchQueues
                      key={product.id}
                      product={product}
                      slug={slug}
                    />
                  ) : (
                    <ProductCard
                      key={product.id}
                      product={product}
                      slug={slug}
                    />
                  )
                )
              ) : (
                <NoResults
                  onClear={handleClearQueryParams}
                  resultsType='products'
                />
              )}
            </ProductsWrapper>
          </PageWrapper>
          {hasAdditionalProduct && (
            <LoadMoreWrapper ref={ref}>
              <PageLoader message='Fetching more results' />
            </LoadMoreWrapper>
          )}
          {confirmAgeModal.isOpen && <ConfirmAgeModal />}
        </>
      )}
    </>
  );
};

LimitedAccess.defaultProps = {
  isPublicRoute: false,
};

LimitedAccess.propTypes = {
  isPublicRoute: PropTypes.bool,
};

export default LimitedAccess;
