import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import NoResults from '../../components/NoResults';
import PageLoader from '../../../shared/components/PageLoader';
import PageWrapper from '../../../shared/components/PageWrapper';
import SellersHeader from '../../components/SellersHeader';
import VendorCard from '../../components/VendorCard';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { getAllSellersData } from '../../../store/actions/storefrontActions';
import { LoadMoreWrapper, VendorsWrapper } from './Sellers.styles';

const Sellers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { ref, inView } = useInView();
  const { sellersPageData } = useStorefrontState();
  const { hasAdditionalSellers, isLoadingAllSellersData, vendors } =
    sellersPageData;

  const [pageNumber, setPageNumber] = useState(0);

  const queryParamsParsed = queryString.parse(location.search);

  const handleClearQueryParams = () => {
    navigate({
      pathname: location.pathname,
      search: '',
    });
    dispatch(getAllSellersData({}));
  };

  useEffect(() => {
    dispatch(
      getAllSellersData({
        queryString: queryString.stringify(queryParamsParsed),
      })
    );
  }, []);

  useEffect(() => {
    if (inView && hasAdditionalSellers) {
      queryParamsParsed.page_num = pageNumber + 1;
      queryParamsParsed.last_vendor = vendors[vendors.length - 1].id;

      dispatch(
        getAllSellersData({
          queryString: queryString.stringify(queryParamsParsed),
          isLoadingMore: true,
        })
      );

      setPageNumber(pageNumber + 1);
    }
  }, [inView]);

  return (
    <>
      <SellersHeader />
      {isLoadingAllSellersData ? (
        <PageLoader />
      ) : (
        <>
          <PageWrapper sidePadding='small'>
            <VendorsWrapper>
              {vendors.length ? (
                vendors.map((vendor) => (
                  <VendorCard key={vendor.id} vendor={vendor} />
                ))
              ) : (
                <NoResults
                  onClear={handleClearQueryParams}
                  resultsType='sellers'
                />
              )}
            </VendorsWrapper>
          </PageWrapper>
          {hasAdditionalSellers && (
            <LoadMoreWrapper ref={ref}>
              <PageLoader message='Fetching more results' />
            </LoadMoreWrapper>
          )}
        </>
      )}
    </>
  );
};

export default Sellers;
